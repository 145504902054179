import { computed } from 'vue'
import { defineStore } from 'pinia'
import { useWindowSize } from '@vueuse/core'

const { width } = useWindowSize()

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
}

export const useBreakpointsStore = defineStore('breakpoints', () => {
  const size = computed(() => {
    switch (true) {
      case width.value < breakpoints.sm:
        return 'sm'
      case width.value < breakpoints.md:
        return 'md'
      case width.value < breakpoints.lg:
        return 'lg'
      case width.value < breakpoints.xl:
        return 'xl'
      default:
        return 'xxl'
    }
  })

  const isMobile = computed(() => ['sm', 'md', 'lg', 'xl'].includes(size.value))

  const isSm = computed(() => size.value === 'sm')

  return { isMobile, isSm }
})
